import { format, parseISO } from "date-fns"
import Link from "next/link"
import { useRouter } from "next/router"
import React from "react"
import useSWR from "swr"

import { Layout } from "@/components/layout/Layout"
import { OutlineButton, Title1Declaration } from "@/components/lib"
import Spinner from "@/components/svg/spinner"
import { findDeclaration } from "@/clients/declarations"
import {
  AuthorSchema,
  DeclarationModel,
  DeclarationModelWithFiness,
  PursuitSchema,
  VictimSchema,
} from "@/models/declarations"
import { Prisma } from "@prisma/client"
import { BadgeType } from "@/components/BadgeType"
import { BadgeLevel } from "@/components/BadgeLevel"
import HeadTitle from "@/components/HeadTitle"
import { getDeclarationPDF } from "@/utils/declarationPDF"
import { useSession } from "next-auth/react"

function getPDF(data: DeclarationModelWithFiness) {
  getDeclarationPDF(data)
}

export const DeclarationTypePart = ({ data }: { data: DeclarationModel }) => {
  return (
    <div className="mt-6">
      <p>
        <span className="inline-block w-48 font-bold">Numéro</span>
        {data.declarationId}
      </p>
      <span className="inline-block w-48 font-bold">Type de déclaration</span>
      <BadgeType type={data.declarationType} />
      {data?.finesset && (
        <p>
          <span className="inline-block w-48 font-bold mt-2">FINESS</span>
          {data.finesset || "N/A"}
        </p>
      )}
      {data?.finesset && (
        <p>
          <span className="inline-block w-48 font-bold mt-2">
            Établissement
          </span>
          {(data as DeclarationModelWithFiness).finess?.rs || "N/A"}
        </p>
      )}
    </div>
  )
}

export const DatePart = ({ data }: { data: DeclarationModel }) => {
  const location = data.location
  const formattedLocation = (key: string) => {
    if (location) {
      if (Array.isArray(location[key]) && location[key]?.[0] === "Autre")
        return `${location[key]?.[0]} (${location[key]?.[1]})`
      else if (Array.isArray(location[key]))
        return location[key]?.map((val: string, index: number) =>
          index === 0 ? `${val} ` : ` ${val} `,
        )
      else return location[key]
    }
  }

  return (
    <>
      <Title1Declaration className="mt-6 mb-4 text-left">
        <b>Date & lieu</b>
      </Title1Declaration>
      <p>
        <span className="inline-block w-48 font-bold">
          Date de la déclaration
        </span>
        {format(parseISO(data.createdAt as unknown as string), "dd/MM/yyyy")}
      </p>
      {data.job && (
        <p>
          <span className="inline-block w-48 font-bold">
            Profession du déclarant
          </span>
          {data.job}
        </p>
      )}
      <p>
        <span className="inline-block w-48 font-bold">
          {"Date de l'évènement"}
        </span>
        {format(parseISO(data.date as unknown as string), "dd/MM/yyyy")}
      </p>
      <p>
        <span className="inline-block w-48 font-bold">Horaire</span>
        {data.hour}
      </p>
      <p>
        <span className="inline-block w-48 font-bold">Ville</span>
        {data.town}
      </p>
      <p>
        <span className="inline-block w-48 font-bold">Code postal</span>
        {data.postalCode}
      </p>
      {location &&
        Object.keys(location)?.map((key) => (
          <p key={key}>
            <span className="inline-block w-48 font-bold">{key}</span>
            {formattedLocation(key)}
          </p>
        ))}
    </>
  )
}

// Utility to display fact and reasons, since they can be of type string or be a tuple of 2 elements.
function prettyDisplay(arrayWithPrecision: Prisma.JsonArray) {
  return arrayWithPrecision.map((fact, index) =>
    !Array.isArray(fact) ? (
      <li key={index} className="list-disc ml-8 pl-2">
        {fact}
      </li>
    ) : (
      <li
        key={index}
        className="list-disc ml-8 pl-2"
      >{`${fact[0]} (${fact[1]})`}</li>
    ),
  )
}

export const FactsPart = ({ data }: { data: DeclarationModel }) => {
  const factPersons = data.factPersons as Prisma.JsonObject
  const factGoods = data.factGoods as Prisma.JsonObject
  const { factPersonsLevel, factGoodsLevel } = data

  return (
    <>
      <Title1Declaration className="mt-6 mb-4">
        <b>Faits</b>
      </Title1Declaration>

      {factPersons && Boolean(Object.keys(factPersons).length) && (
        <>
          <div className="font-bold my-4">
            Atteinte aux personnes
            {typeof factPersonsLevel === "number" && (
              <div className="inline-block ml-4">
                <BadgeLevel level={factPersonsLevel} label="Niveau" />
              </div>
            )}
          </div>

          {Object.keys(factPersons).map((key) => (
            <div key={key}>
              <span className="inline-block">{key}</span> :&nbsp;
              <ul>
                {Array.isArray(factPersons[key]) &&
                  (factPersons[key] as Prisma.JsonArray).length &&
                  prettyDisplay(factPersons[key] as Prisma.JsonArray)}
              </ul>
            </div>
          ))}
        </>
      )}
      {factGoods && Boolean(Object.keys(factGoods).length) && (
        <>
          <div className="font-bold my-4">
            Atteinte aux biens
            {typeof factGoodsLevel === "number" && (
              <div className="inline-block ml-4">
                <BadgeLevel level={factGoodsLevel} label="Niveau" />
              </div>
            )}
          </div>

          {Object.keys(factGoods).map((key) => (
            <p key={key}>
              <span className="inline-block">{key}</span>
              {Array.isArray(factGoods[key]) &&
                (factGoods[key] as Prisma.JsonArray).length &&
                prettyDisplay(factGoods[key] as Prisma.JsonArray)}
            </p>
          ))}
        </>
      )}
    </>
  )
}

export const ReasonsPart = ({ data }: { data: DeclarationModel }) => {
  const reasons = data.reasons as Prisma.JsonObject

  return (
    <>
      <Title1Declaration className="mt-6 mb-4">
        <b>Motifs</b>
      </Title1Declaration>

      {data.reasonNotApparent && (
        <p>
          <span className="inline-block">Pas de motif apparent</span>
        </p>
      )}
      {reasons && Boolean(Object.keys(reasons).length) && (
        <>
          {Object.keys(reasons).map((key) => (
            <p key={key}>
              <span className="inline-block">{key}</span>
              {Array.isArray(reasons[key]) &&
                (reasons[key] as Prisma.JsonArray).length &&
                prettyDisplay(reasons[key] as Prisma.JsonArray)}
            </p>
          ))}
        </>
      )}
    </>
  )
}

export const VictimsAuthorsPart = ({ data }: { data: DeclarationModel }) => {
  const victims = data?.victims as Prisma.JsonArray
  const authors = data?.authors as Prisma.JsonArray
  const pursuit = data?.pursuit as PursuitSchema
  const pursuitBy = pursuit && pursuit["pursuitBy"]
  const thirdParty = data?.thirdParty as Prisma.JsonArray

  return (
    <>
      <Title1Declaration className="mb-4">
        <b>Victimes & auteurs</b>
      </Title1Declaration>

      {victims?.map((victim: VictimSchema, index) => (
        <div key={index} className="mb-6">
          <div className="mb-4">
            <p className="font-bold">Victime n°{index + 1}</p>
            {victim.type}
            {victim.gender && ` de sexe ${victim.gender}`}
            {victim.age && ` et âgé de ${victim.age}`}
            {victim.healthJob && (
              <>&nbsp;dont la profession est {victim.healthJob}</>
            )}
            .
          </div>
          {!!victim.sickLeaveDays && (
            <p>
              <span className="inline-block w-48 ">
                {"Jours d'arrêt de travail"}
              </span>
              {victim.sickLeaveDays}
            </p>
          )}
          {!!victim.hospitalizationDays && (
            <p>
              <span className="inline-block w-48 ">
                {"Jours d'hospitalisation"}
              </span>
              {victim.hospitalizationDays}
            </p>
          )}
          {!!victim.ITTDays && (
            <p>
              <span className="inline-block w-48 ">{"Jours d'ITT"}</span>
              {victim.ITTDays}
            </p>
          )}
        </div>
      ))}
      {pursuit && (
        <p className="flex">
          <span className="inline-block w-48 ">Suites judiciaires</span>
          {pursuit.type}
        </p>
      )}
      {pursuitBy?.length && (
        <p className="flex">
          <span className="inline-block w-48 ">Par</span>
          {pursuitBy.join(", ")}
        </p>
      )}

      {authors?.map((author: AuthorSchema, index) => (
        <div key={index} className="mt-6">
          <div className="mb-4">
            <p className="font-bold">Auteur n°{index + 1}</p>
            {author.type}
            {author.gender && ` de sexe ${author.gender}`}
            {author.age && ` et âgé de ${author.age}`}
            {author.healthJob && `dont la profession est ${author.healthJob}`}.
          </div>
          {!!author.discernmentTroubles?.length && (
            <p className="flex">
              <span className="inline-block w-48 ">
                Altération du discernement
              </span>
              {author.discernmentTroubles.join(", ")}
            </p>
          )}
        </div>
      ))}

      {thirdParty && (
        <p className="flex">
          <span className="inline-block w-48">Intervention de tiers</span>
          {thirdParty
            ?.map((elt) =>
              Array.isArray(elt) ? `${elt?.[0]} (${elt?.[1]})` : elt,
            )
            .join(", ")}
        </p>
      )}
    </>
  )
}

export const FinalPrecisionsPart = ({ data }: { data: DeclarationModel }) => {
  return (
    <>
      <Title1Declaration className="mt-6 mb-4">
        <b>Précisions</b>
      </Title1Declaration>

      <div className="flex">
        <p className="font-bold w-48 flex-shrink-0">Description</p>
        <p>{data.description}</p>
      </div>
      {/* Those data doesn't exist for hospital flow */}
      {data.declarantContactAgreement !== null && (
        <p>
          <span className="inline-block font-bold w-48">Consentement</span>
          {data.declarantContactAgreement === true ? "Oui" : "Non"}
        </p>
      )}
      {data.declarantContactAgreement && (
        <>
          <p>
            <span className="inline-block w-48 font-bold ">Nom Prénom</span>
            {data.declarantNames}
          </p>
          <p>
            <span className="inline-block w-48 font-bold ">N° RPPS/ADELI</span>
            {data.declarantExternalId}
          </p>
          <p>
            <span className="inline-block w-48 font-bold ">Courriel</span>
            {data.declarantEmail}
          </p>
          <p>
            <span className="inline-block w-48 font-bold ">Téléphone</span>
            {data.declarantTel}
          </p>
        </>
      )}
    </>
  )
}

const ShowDeclarationPage = (): JSX.Element => {
  const router = useRouter()
  const { id } = router.query
  const { data: session } = useSession()
  const { data, error } = useSWR(
    id ? ["/api/declarations", id] : null,
    (url, id) => findDeclaration(id),
  )

  if (error) console.error(error.info?.error)

  return (
    <>
      <Layout>
        <HeadTitle title="Détails de la déclaration" />
        <div
          className="px-16 py-4 m-auto mb-8 shadow-xl max-w-xxl"
          style={{ maxWidth: 800 }}
        >
          <Title1Declaration className="mt-12">
            <b>{"Votre déclaration"}</b>
          </Title1Declaration>

          {!error && !data && (
            <div className="flex items-center justify-center">
              <Spinner />
            </div>
          )}

          {error && (
            <>
              <Title1Declaration className="mt-12">
                {
                  "Il semble qu'il y ait des problèmes pour récupérer les informations de cette déclaration..."
                }
              </Title1Declaration>
            </>
          )}

          {data && (
            <>
              <DeclarationTypePart data={data} />
              <DatePart data={data} />
              <FactsPart data={data} />
              <ReasonsPart data={data} />
              <VictimsAuthorsPart data={data} />
              <FinalPrecisionsPart data={data} />
            </>
          )}

          <div className="flex justify-center w-full my-16 space-x-4">
            {session ? (
              <>
                {data && (
                  <OutlineButton onClick={() => getPDF(data)}>
                    Télécharger cette déclaration
                  </OutlineButton>
                )}
                <Link href="/private">
                  <OutlineButton>Retour au tableau de bord</OutlineButton>
                </Link>
              </>
            ) : (
              <Link href="/">
                <OutlineButton>Retour à la page principale</OutlineButton>
              </Link>
            )}
          </div>
        </div>
      </Layout>
    </>
  )
}

export default ShowDeclarationPage
